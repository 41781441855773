import "./App.css";
import Charge from "./page/Charge";
import Testpay from "./page/Testpay";
import Realname from "./page/realName";
import GetCode from "./page/Login/GetCode";
import { Route, Routes } from "react-router-dom";
import MobileLogin from "./page/Login/MobileLogin";
import Withdraw from "./page/Login/Withdraw";
import SignPage from "./page/Login/SignPage";
import WithdrawalList from "./page/WithdrawalList";
import AccountList from "./page/AccountList";
import WithdrawalDetail from "./page/WithdrawalDetail";
import Chargewin from "./page/Charge/chargeWin";
import PayWin from "./page/Charge/PayWin";
// import Txt from "./page/MP_verify_Ng6qxvplE7hW9Enx";
import { useEffect, useState } from "react";
import UAParser from "ua-parser-js";
import Http from "./utils/Http";

const App = () => {
  const [openID, setOpenID] = useState(null);
  const [token, setToken] = useState(null);

  const wechatOauthCheck = async () => {
    const ua = new UAParser();
    if (ua.getBrowser().name !== "WeChat") {
      return;
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.open_id) {
      setOpenID(params.open_id);
      return;
    }

    const res = await Http.postForm("/api/v1/wechat_public/getAuthUrl", {
      redirect_uri: window.location.href,
    });
    // console.log(res.data, "-res---===");
    // let path =
    //   "http://192.168.0.108:3000/charge?open_id=oxCGC6U96BEA3gSFsS_29c8XTnmI";
    if (res.status === 10000) {
      window.location.href = res.data;
    }
  };
  useEffect(() => {
    wechatOauthCheck();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<GetCode />} />
        <Route path="/charge" element={<Charge openID={openID} />} />
        <Route path="/testpay" element={<Testpay openID={openID} />} />
        <Route path="/mobile" element={<MobileLogin />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/signpage" element={<SignPage />} />
        <Route path="/withdrawalList" element={<WithdrawalList />} />
        <Route path="/accountList" element={<AccountList />} />
        <Route path="/withdrawalDetail" element={<WithdrawalDetail />} />
        {/* <Route path="/MP_verify_Ng6qxvplE7hW9Enx.txt" element={<Txt />} /> */}
        <Route path="/realname" element={<Realname />} />
        <Route path="/chargewin" element={<Chargewin />} />
        <Route path="/PayWin" element={<PayWin />} />
      </Routes>
    </div>
  );
};

export default App;
